.tapperContainer {
	position: absolute;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100vw;
	z-index: 10000;
	background: black;
	left: 0;
	right: 0;
	margin: auto;
	top: 0;
	text-align: left;
}

.tapperContent {
	position: relative;
}

.xPosition {
	position: absolute;
	top: 1em;
	left: 1em;
	z-index: 10000;
	position: fixed;
}

.slideContainer {
	position: relative;
	width: 100vw;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
}

.slideImg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.slideImg > img, .slideImg > video {
	object-fit: cover;
	object-position: 50% 50%;
	width: 100%;
	height: 100%;
}

.prevTap, .nextTap {
	position: absolute;
	top: 0;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
}
.prevTap {
	left: 0;
	width: 30%;
}
.nextTap {
	right: 0;
	width: 70%;
}

.tapperTextBox {
	position: absolute;
	bottom: 7.5em;
	width: 90%;
	padding: 1.5em 1em;
	background-color: #000000c9;
}

.slideCounter {
	bottom: 1em;
	bottom: calc(var(--vh, 1vh) * 2);
	right: 1em;
	position: absolute;
}

.button {
	border: 2px solid white;
	position: absolute;
	bottom: 1em;
	bottom: calc(var(--vh, 1vh) * 1);
	left: 1em;
	padding: .75em;
	background-color: #4a1444a3;
}

.returnTop {
	top: .5em;
	bottom: unset;
	z-index:100;
}

.initiativeImpact {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	padding: 5em 1em 0em;
	position: relative;
	overflow: auto;
	transition: transform 1000ms;
	z-index: 10000;
	background: black;
}

.initiativeImpact.slideUp {
	transform: translateY(-100vh);
	transform: translateY(calc(var(--vh, 1vh) * -100));
}

.initiativeImpact > h1 {
	z-index: 100;
	position: relative;
}

.headerBlurImg {
	position: absolute;
	z-index: -1;
	top:0;
	left:0;
	width: 100%;
	height: 15em;
	background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(74,20,68,1) 100%); 
}
.decorativeSeparator {
	height: 4px;
	margin-left: -1em;
	background-color: white;
	width: 91%;
	margin-top: -1em;
}
.quoteBy {
	font-size: 82%;
	text-align: right;
	width: 80%;
	float: right;
	margin-top: 1em;
}

.quoteBy > p {
	margin: 0;
}

.panelTitle {
	position: absolute;
	z-index: 10000;
	background: black;
	right: 0em;
	top: 1.5em;
	padding: .3em 1em;
}
.panelTitle > h1 {
	font-size: 2em;
}

.learnMoreContainer {
	display: flex;
	justify-content: center;
	margin-top: 2em;
	margin-bottom: 5em;
}

.learnMoreButton {
	display: inline-block;
	border: 2px white solid;
	padding: .5em 1em;
	color: white;
	margin-bottom: 1em;
	text-transform: uppercase;
}

.creditsContainer {
	margin-left: 1em;
}


/*.marker-inner {
	width: 1em;
	height: 1em;
	background: white;
	border-radius: 100%;
}*/

.colorContainer {
	margin: 1em 0 1em 2em;
}

.colorContainer.mapKey {
	position: absolute;
	top: 1em;
	left: 1em;
	z-index: 1000;
	background: #00000080;
	padding: 1em 1em .5em 1em;
	font-size: .75em;
	margin: unset;
}

.colorEntry {
	margin-bottom: .5em;
}

.colorCircle.blob {
	width: 1.5em;
	height: 1.5em;
	border-radius: 50%;
	margin: unset;
	margin-right: .5em;
}

@media (min-width: 768px) {
	.tapperContainer {
	  	width: 36em;
	}

	.colorContainer {
		display: none;
	}
	.colorContainer.mapKey {
		display: unset;
	}

	.panelTitle {
		font-size: 3em;
		right: .5em;
		top: 1.25em;
		padding: .2em 1em;
	}

	.slideImg {
/*		width: 80%;
		margin: 3em auto;
		right: 0;
		height: 85vh;*/
		height: 95.5vh;
		height: calc(var(--vh, 1vh) * 95.5);
		width: auto;
		margin: 1.5em;
		right: 0;
	}

	.static-scrollyteller-img.tapper {
		height: inherit;
	}

	.slideContainer {
		width: unset;
	}

	.tapperTextBox {
		width: 83%;
		left: 0;
		bottom: 9em;
		font-size: 120%;
	}

	.button {
		left: 2.5em;
		bottom: 3.75vh;
		bottom: calc(var(--vh, 1vh) * 3.75)
	}

	.returnTop {
		left: 1.5em;
		bottom: unset;
		top: 1em;
	}

	.slideCounter {
		right: 3em;
		bottom: 3.75vh;
		bottom: calc(var(--vh, 1vh) * 3.75);
	}

	.initiativeImpact {
		height:calc(var(--vh, 1vh) * 88);
	    margin: 1em;
	    padding: 5em 3em 0;
	    overflow: auto;
	}
	.learnMoreContainer {
		margin-bottom: .5em;
	}

	.xPosition {
		top: 2.5em;
		left: 2.5em;
		position: absolute;
	}

	.quoteBody {
		margin-bottom: 0;
	}

}
@media (min-width: 650px) and (max-width: 768px) {
	.colorContainer {
	    margin: 1em 0 1em 0;
	}
	.colorContainer.mapKey > .tabletColorContainerFlex {
		display: unset;
		justify-content: unset;
		margin-top: unset;
		margin-bottom: unset;
	}
	.tabletColorContainerFlex {
		display: flex;
		justify-content: space-evenly;
		margin-top: 1em;
		margin-bottom: 1em;
	}
}