.conspiracy-instructions {
	left: 0;
    position: absolute;
    bottom: 50vh;
    width: 88%;
    margin: 0 auto;
    right: 0;
    text-align: left;
}

.conspiracy-init-container {
	position: absolute;
    bottom: 3em;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.conspiracy-init-container > p {
	font-size: 1.2em;
	margin-bottom: .5em;
}

.conspiracy-init {
    width: 3em;
}

.conspiracy-card-container {
	position: relative;
	width: 80vw;
    height: 85vw;
	margin: 2em auto;
}

.card-front-banner {
	width: 100%;
    background: black;
    padding: 1em;
    text-transform: uppercase;
	font-weight: bolder;
}

.cardBack-header {
	width: 3em;
    padding: .5em .6em .3em;
    position: absolute;
    top: .3em;
}

.cardBack.conspiracy {
	display: flex;
    align-items: center;
}

.cardBack-text-body {
	padding: .5em 1.5em;
    text-align: left;
}

.cardBack-text-body > a {
	color: white;
}

.scroll-prompt {
	position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 10em;
    font-size: 1.5em;
    top: 85vh;
	animation: fade-in-out 4s forwards;
	padding: .6em .5em .3em;
}

.scroll-down {
	width: 2em;
    margin: 0 auto;
    margin-top: .3em;

}

@media screen and (min-width: 1000px) {
	.conspiracy-interactive-container {
		margin: auto;
		width: 100vw;
/*	    width: calc(var(--vh, 1vh)*135*0.45);
*/	}
}

@media only screen and (min-width: 760px) {

	.conspiracy-card-organizer {
		display: flex;
		flex-wrap: wrap;
	}

	.conspiracy-card-container {
		width: 40vw;
    	height: 40vw;
    	font-size: 1.3em;
	}
}