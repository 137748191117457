html {
	scrollbar-width: none;
}

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-ms-scroll-chaining: none;
	overscroll-behavior: none;
	scrollbar-width: none;
	-webkit-overflow-scrolling: auto;
	background: black;
}

body::-webkit-scrollbar {
  width: 0px;
  background: transparent; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-device-width: 768px) {
	body > #root {
	  width: 100vw;
	  height: 100vh;
	  overflow-y: scroll;
	  -webkit-overflow-scrolling: touch;
	}

	html,
	body {
	  position: fixed;
	  overflow: hidden;
	}
}