.timeline-container {
	width: 100vw;
    padding-right: 15px;
	position: relative;
    top: 0;
    left: 0;
    text-align: left;
    background-color: #000000;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    overflow: scroll;
    height: 100%;
}

.timeline-header {
    z-index: 1;
    margin: 2em;
}

.timeline-header > h1{
	text-transform: uppercase;
    font-size: 1.5em;
   	margin-bottom: 0em;
}

.timeline-header span {
	font-size: 137%;
}

.timeline-header > p {
	margin-top: .5em;
}

.tl-marker-full-prev {
    position: absolute;
    bottom: -5.35em;    
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    padding: 1em;
    top: 14.5%;
    left: 300%;
    width: 67vw;
    border: 4px white solid;
    height: fit-content;
    background: #00000099;
}

.tl-spacing-container {
    height: 100%;
}

.tl-marker-full-container {
    position: relative;
    width: 100%;
    height: 30em;
    margin-bottom: 10em;
}

.tl-marker-title {
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    padding: 1em;
    bottom: 2em;
    left: 20%;
    width: 67vw;
    border: 4px white solid;
    height: fit-content;
    background: #00000099;
}

.tl-marker-title h2 {
	margin-top: -.2em;
    margin-bottom: .2em;
    text-align: center;
}

.tl-marker-container {
	width: 3.5em;
}

.tl-connector {
    height: 4px;
    position: absolute;
    width: 1em;
    left: -6%;
    background: white;
    top: 0;
    bottom: 0;
    margin: auto;
}

.the-line {
	height: 210em;
	width: .4em;
	background: white;
	position: absolute;
	left: 15%;
	margin: auto;
	z-index: 1;
}

.background-etching {
	width: 25vw;
    position: absolute;
    left: 35vw;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    filter: brightness(2);
}


.timeline-marker-container {
    width: 95%;
    height: 65vh;
    margin: 0 auto;
}

.kb-slide-container {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.kb-slide-inner {
    display: flex;
    align-items: center;
    height: 100vh;
}

.kb-bg {
    width: 150%;
    position:absolute;
    top: 0;
    bottom: 0;
    left: 0;
}

.bg-slide-left {
    animation: bg-slide-left 40s linear infinite;
}

.bg-slide-right {
	animation: bg-slide-right 40s linear infinite;

}

.tap-nav-button {
    position: fixed;
    z-index: 18;
    width: 2.4em;
    bottom: .5em;
}

.tap-nav-button.advance {
    right: 2em;
}

.tap-nav-button.prev {
    left: 2em;
}

.tap-nav-button.audio-toggle {
    left: 0;
    right: 0;
    margin: auto;
    width: 2.45em;
    z-index: 19;
}

.instruction-suggestion {
    position: fixed;
    bottom: 3.5em;
    left: 0;
    right: 0;
    animation: fade-in-out 4s forwards;
    background: #000000c7;
    padding: .5em;
}

.instruction-suggestion.always-show {
    display: block;
    z-index: 22;
}

.text-box-default.KB {
    z-index: 21;
    position: absolute;
    height: fit-content;
    margin: auto;
    max-height: unset;
    max-width: unset;
    width: 60%;
}

.death-slide-kb {
    font-size: 2em;
    width: 10em;
    text-align: center;
    z-index: 21;
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    margin: auto;
    text-transform: uppercase;
}

.KB.fade-transition {
  animation: faderIn 1ms forwards;
}

.KB.fade-transition-2 {
  animation: faderInto 1ms forwards;
}

@keyframes bg-slide-left {
    from { transform: translateX(0); }
    to { transform: translateX(-12%); }
}

@keyframes bg-slide-right {
    from { transform: translateX(-12%); }
    to { transform: translateX(0%); }
}

.lightbox-animate-enter {
    opacity: 0;
    transform: scale(0.9);
    -webkit-transform:scale(0.9);
    -moz-transform:scale(0.9);
    -o-transform:scale(0.9); 
}
.lightbox-animate-enter-active {
    opacity: 1;
    -webkit-transform:translateX(0);
    -moz-transform:translateX(0);
    -o-transform:translateX(0); 
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
    -moz-transition: opacity 300ms, transform 300ms; /* Firefox 4 */
    -webkit-transition: opacity 300ms, transform 300ms; /* Safari 和 Chrome */
    -o-transition: opacity 300ms, transform 300ms; /* Opera */
}
.lightbox-animate-exit {
  opacity: 1;
}
.lightbox-animate-exit-active {
    opacity: 0;
    -webkit-transform:scale(0.9);
    -moz-transform:scale(0.9);
    -o-transform:scale(0.9); 
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
    -moz-transition: opacity 300ms, transform 300ms; /* Firefox 4 */
    -webkit-transition: opacity 300ms, transform 300ms; /* Safari 和 Chrome */
    -o-transition: opacity 300ms, transform 300ms; /* Opera */
}

@media only screen and (min-width: 768px) 
  and (max-width: 1024px) {
     .tl-connector {
        width: 7.5vw;
        left: -14%;
    }

    .tl-marker-full-container {
        height: 35em;
        background-size: 50% !important;
    }

    .tl-marker-title {
        left: 0;
        right: 0;
        bottom: 4em;
        margin: auto;
        width: 24em;
    }
}

@media only screen and (min-width: 1024px) {

    .text-box-default.KB {
        z-index: 21;
        position: absolute;
        height: fit-content;
        margin: auto;
        max-height: unset;
        max-width: unset;
        width: 60%;
    }

    .the-line {
        width: 100%;
        height: .4em;
        background: white;
        position: absolute;
        bottom: 30vh;
        margin: auto;
        z-index: 1;
        left: 0;
    }


    .timeline-container {
        width: 400vw;
        height: 100vh;
        text-align: left;
    }

    .death-slide-kb {
        font-size: 3em;
    }

    .timeline-header {
        width: 27em;
    }

	.timeline-header > h1{
	    font-size: 2em;
	}

	.timeline-header > p {
		font-size: 1.4em;
	}

    .timeline-marker-container {
        width: 33vw;
        height: 100%;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
    }

    .tl-spacing-container {
        height: 100vh;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;
    }

    .tl-marker-full-container {
        width: 34em;
        height: 100%;
        margin-bottom: 0em;
    }

    .tl-marker-full {
        position: absolute;
        bottom: -5.35em;    
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: center;
        align-items: center;
    }

    .tl-marker-title {
        left: 0;
        right: 0;
        bottom: 4em;
        margin: auto;
        width: auto;
    }

    .tl-connector {
        width: 4px;
        height: 9vh;
        top: -22.3vh;
        left: 0;
        right: 0;
        margin: auto;
    }

    .text-box-default.KB {
        width: 20em;
        max-height: unset;
        max-width: unset;
    }

}
