.disaster-map-main {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
}

.sidebar-container {
  position: absolute;
  bottom: 0;
  padding-left: 2em;
  z-index: 1000;
  -webkit-box-shadow: -7px -4px 11px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: -7px -4px 11px -5px rgba(0,0,0,0.75);
  box-shadow: -7px -4px 11px -5px rgba(0,0,0,0.75);
  background-color: black;
  height: 55vh;
  border-left: 1px solid #484a4c;
  transition: bottom 1s, height 550ms;
}

.sidebar-container.stretched {
  height: 85vh;
  height: calc(var(--vh, 1vh) * 85);
}

.sidebar-container.hide {
  height: 0vh;
}

.disaster-header-container {
  width: 82vw;
  height: calc(82vw * .64);
}

.disaster-lightbox-feature {
  width: 82vw;
  height: 82vw;
  position: relative;
}

.caption-icon {
  margin: .5em;
  bottom: 0;
  width: 2em;
  height: 2em;
  background: black;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  pointer-events: all;
}

.caption-full {
  bottom: 0;
  margin: .5em;
  background: black;
  position: absolute;
  overflow: scroll;
  padding: .7em;
  pointer-events: all;
  scrollbar-width: none;
}

.caption-full.no-scroll {
  overflow: unset;
  padding: .9em;
  text-align: left;
  border: 2px white solid;
}

.close-tab {
  width: 3em;
  height: 2em;
  background: inherit;
  position: absolute;
  top: -2em;
  right: 1em;
  z-index: 100000;
  -webkit-box-shadow: 2px 0px 11px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 2px 0px 11px 2px rgba(0,0,0,0.75);
  box-shadow: 2px 0px 11px 2px rgba(0,0,0,0.75);
}

.detail-close-arrow {
  transform: rotate(180deg);
  transition: transform 800ms;
}

.sidebar-content {
  text-align: left;
  overflow-y: auto;
  height: inherit;
  padding-right: 2em;
  overscroll-behavior: none;
}
.sidebar-content.scrollable {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.sb-text-area {
  margin-top: 3em;
  margin-bottom: 6em;
}

/* GALLERY STYLES */

.disaster-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.gallery-thumbnail {
  height: 100%;
  width: 100%;
}

.gallery-controls {
  margin-top: 1em;
  justify-content: space-around;
}

.library-grid {
  margin-top: 1em;
  display: grid;
  grid-template-columns: 22vw 22vw 22vw;
  grid-auto-rows: 22vw;
  grid-gap: 1em;
  gap: 1em;
  justify-content: center;
}

/* LEAFLET OVERRIDES */
.popup-dark .leaflet-popup-content-wrapper {
	background: black;
	color: white;
	border-radius: 0px;
	outline-offset: -20px; 
  outline: 2px solid #fff;
  text-align: left;
  padding: 2em;
  font-size: 115%;
  font-family: "Futura", sans-serif;
}

.popup-dark .leaflet-popup-content-wrapper, .leaflet-popup-tip {
	background: black;
}

.leaflet-container a.leaflet-popup-close-button  {
    color: white !important;
    margin: 0px -2px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.leaflet-container a.leaflet-popup-close-button::selection,::moz-selection {color:currentColor;background:transparent}

.leaflet-control-layers-overlays {
    text-align: left !important;
}

.leaflet-control-layers.leaflet-control {
  background: black;
  color: white;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar > a {
  background: black;
  color: white;
}

/* LAYER SELECTOR COLORS */
.leaflet-control-layers-overlays > label:first-of-type > div > span:before {
    background: #8ecea0;
}
.leaflet-control-layers-overlays > label:nth-of-type(2n) > div > span:before {
    background: #7d7dd0;
}
.leaflet-control-layers-overlays > label:last-of-type > div > span:before {
    background: #da8470;
}


/* ICON STYLES */
.leaflet-div-icon {
    background: none;
    border: none;
}

/* CUSTOM CHECKBOXES */
input[type="checkbox"].leaflet-control-layers-selector {
  display: none;
}

.leaflet-control-layers-overlays > label {
    margin: 5px 5px;
    font-family: "Futura", sans-serif;
}

span {
  cursor: pointer;
}
input[type="checkbox"] + span:before {
  border: 1px solid #333;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 .25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
}
input[type="checkbox"]:checked + span:before {
  background: #fff;
  color: #000;
  content: "\2613";
  text-align: center;
}

input[type="checkbox"]:focus + span::before {
    outline: rgb(59, 153, 252) auto 5px;
}

@media only screen and (min-width: 768px) {
  .sidebar-container {
    position: absolute;
    right:0;
    top: 0;
    padding-left: 4em;
    -webkit-box-shadow: -7px -4px 11px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: -7px -4px 11px -5px rgba(0,0,0,0.75);
    box-shadow: -7px -4px 11px -5px rgba(0,0,0,0.75);
    height: 100vh; 
    width: 27vw;
    transition: right 1s;
  }

  .sidebar-container.hide {
    height: 100vh; 
    right: -31vw;
  }

  .disaster-header-container {
    width: 23vw;
    height: calc(23vw * .64);
  }

  .disaster-lightbox-feature {
    width: 23.5vw;
    height: 23.5vw;
  }
  .sidebar-content {
    padding-right: 3em;
    overflow-y: auto;
  }

  .sb-text-area {
    margin-top: 4.5em;
  }

  .close-tab {
    width: 1.75em;
    height: 3em;
    left: -1.75em;
    bottom: 1.5em;
    top: unset;
    display: flex;
  }

  .detail-close-arrow {
    transform: rotate(270deg);
    transition: transform 500ms;
  }
  .library-grid {
    grid-template-columns: 7vw 7vw 7vw;
    grid-auto-rows: 7vw;
  }
}
