.App {
  text-align: center;
  font-family: Futura, sans-serif;
  color: white;
  z-index: 1;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: black;
  scrollbar-width: none;
}
.App::-webkit-scrollbar {
  width: 0px;
  background: black; 
}

.big-ol-div {
  height: 400vh;
  background: rgba(38,31,19,1);
  background: -moz-linear-gradient(top, rgba(38,31,19,1) 0%, rgba(182,141,76,1) 51%, rgba(233,212,179,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(38,31,19,1)), color-stop(51%, rgba(182,141,76,1)), color-stop(100%, rgba(233,212,179,1)));
  background: -webkit-linear-gradient(top, rgba(38,31,19,1) 0%, rgba(182,141,76,1) 51%, rgba(233,212,179,1) 100%);
  background: -o-linear-gradient(top, rgba(38,31,19,1) 0%, rgba(182,141,76,1) 51%, rgba(233,212,179,1) 100%);
  background: -ms-linear-gradient(top, rgba(38,31,19,1) 0%, rgba(182,141,76,1) 51%, rgba(233,212,179,1) 100%);
  background: linear-gradient(to bottom, rgba(38,31,19,1) 0%, rgba(182,141,76,1) 51%, rgba(233,212,179,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#261f13', endColorstr='#e9d4b3', GradientType=0 );
}

.nav-F {
  width: 3em;
  position: fixed;
  right: .5em;
  top: .5em;
  z-index: 1000000;
}

.audio-icon {
  position: fixed;
  z-index: 1;
  top: .94em;
  right: 3.7em;
  width: 2.5em;
}

/* SPINNER */

.loader {
  position: absolute;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  animation: load3 1s infinite linear;
  box-shadow: 0em 0em 2em 3em rgb(255, 255, 255), 0em 0em 2em 3em rgb(255, 255, 255) inset;
  border-radius: 1em;
}

.loader.noShadow {
  box-shadow: unset;
}

.bootstrap-loader {
  position: absolute;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid white;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: load3 .75s linear infinite;
  animation: load3 .75s linear infinite;
}

.proj-loader{
  width: 4em;
  position: fixed !important;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.linktree-container {
  width: 100vw;
  margin: 4em 0em 7em 0em;
  position: absolute;
  display:none;
}
.linktree-container > h2 {
  text-align: left;
  margin-top: 2em;
  font-size: 1.7em;
  margin-left: .5em;
}
.linktree-block {
  border: 4px solid #fff;
  margin: 1em 2em;
  max-height: 3.5em;
}
.linktree-block > a {
  width: 100%;
  height: 100%;
  display: inline-block;
  text-decoration: none;
  color: white;
  font-weight: bold;
}

/* UTILTY ALL-PURPOSE STYLES*/

.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.space-evenly {
  justify-content: space-evenly;
}
.flex-col {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.pos-abso {
  position: absolute;
}
.pos-rel {
  position: relative;
}
.margin-auto {
  margin: auto;
}
.margin-top-1 {
  margin-top: 1em;
}
.margin-bottom-0 {
  margin-bottom: 0em;
}
.margin-bottom-5em {
  margin-bottom: 5em;
}
.text-left {
  text-align: left;
}
.padding-1em {
  padding: 1em;
}
.padding-1x {
  padding: 0em 1em;
}
.left0 {
  left: 0;
}
.right0 {
  right: 0;
}
.top0 {
  top: 0;
}
.top25 {
  top: 25vh;
}
.top35 {
  top: 35vh;
}
.bottom0 {
  bottom: 0;
}
.pointer {
  cursor: pointer;
}
.font-large {
  font-size: large;
}
.opacity-0 {
  opacity: 0;
}
.opacity-1 {
  opacity: 1;
}
.cursor-pointer {
  cursor: pointer;
}
.inline-block {
  display: inline-block;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pointer-events-none {
  pointer-events: none;
}

@media only screen and (min-width: 768px) {
  .linktree-container {
    width: 40em;
    margin: 4em auto 0;
  }
  .linktree-block {
    margin: 1em;
  }
}