.card {
	perspective: 1000px;
	-webkit-perspective: 1000px;
	-moz-perspective: 1000px;
	-o-perspective: 1000px;
	-ms-perspective: 1000px;
	vertical-align:top;
	display:block;
	-webkit-transform:translate3d(0,0,0);
	padding-right: 15px;
}

.card.conspiracy {
	height: 100%;
	width: inherit;
}

.cardFront.conspiracy, .cardBack.conspiracy {
	border: 4px white solid;
	height: inherit !important;
}

.cardBack.conspiracy {
	background: black !important;
}

.card .content {
	transition: .4s ease-in-out;
	-webkit-transition: .4s ease-in-out;
	-moz-transition: .4s ease-in-out;
	-o-transition: .4s ease-in-out;
	-ms-transition: .4s ease-in-out;
	transform-style: preserve-3d;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-o-transform-style: preserve-3d;
	-ms-transform-style: preserve-3d;

	backface-visibility: visible;
	-webkit-backface-visibility: visible;
	-moz-backface-visibility: visible;
	-o-backface-visibility: visible;
	-ms-backface-visibility: visible;

	position:relative;
	width: 100%;
	height: 100%;
	padding-right: 15px;
}

.card.applyflip .content {
	transform: rotateY(180deg);
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	-o-transform: rotateY(180deg);
	-ms-transform: rotateY(180deg);
}

.card .content .cardFront {
    background-color: black;
}

.card .content .cardBack {
    background-color: white;
}


.trailBG {
	background-size: 100%; 
	background-repeat: repeat-y; 
	background-position-x: center;
}

.card .content .cardFront, .card .content .cardBack {
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-o-backface-visibility: hidden;
	-ms-backface-visibility: visible;
	position: absolute;
	top: 0;
	left: 0;
	height: auto;
	width: 100%;
	text-align: center;
}
.card .content .cardFront, .card.applyflip .content .cardFront {
	transform: rotateY(0deg);
	-webkit-transform: rotateY(0deg);
	-moz-transform: rotateY(0deg);
	-o-transform: rotateY(0deg);
	-ms-transform: rotateY(0deg);
}

.card .content .cardBack, .card.applyflip .content .cardBack {
	transform: rotateY(-180deg);
	-webkit-transform: rotateY(-180deg);
	-moz-transform: rotateY(-180deg);
	-o-transform: rotateY(-180deg);
	-ms-transform: rotateY(-180deg);
}


@media only screen and (min-width: 760px) {
	.card .content .cardFront {
	    height: inherit !important;
	}

	.card .content .cardBack {
	    height: inherit !important;
	}
}