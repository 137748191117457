.twitter-timeline-container {
	min-height: 100vh;
	width: 500vw;
	display: flex;
	height: 100vh;
}

.twitter-timeline-container.slideLeft {
    animation: slideLeftBigTime 2200ms forwards;
}

.twitter-timeline-container.reverse {
    animation: slideRightBigTime 2200ms forwards;
}

@keyframes slideLeftBigTime {
	0% {
		transform: translateX(0vw);
	}
	25% {
		transform: scale(.7) translateX(-50vw);
	}
	75% {
		transform: scale(.7) translateX(-475vw);
	}
	100% {
		transform: scale(1) translateX(-400vw);
	}
}

@keyframes slideRightBigTime {
	0% {
		transform: translateX(-400vw);
	}
	25% {
		transform: scale(.7) translateX(-500vw);
	}
	75% {
		transform: scale(.7) translateX(-100vw);
	}
	100% {
		transform: scale(1) translateX(0vw);
	}
}

.slide-animation-enter-active {
	animation: slideLeftBigTime 2200ms forwards;
}
.slide-animation-enter-done {
    transform: scale(1) translateX(-400vw);
}
.slide-animation-exit-active {
    animation: slideRightBigTime 2200ms forwards;
}
.slide-animation-exit-done {
	transform: scale(1) translateX(0vw);
}

.twitter-timeline-container > h1 {
	text-align: left;
}

.twitter-timeline-container > p {
	text-align: left;
}

.pact-timeline-icon-container {
	width: 12em;
	height: 3em;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 3px white solid;
	margin: 1em auto;
	font-weight: bold;
	background: #00000063;
}

.pact-timeline-icon-container > img {
	padding-left: .6em;
}

.pact-timeline-panel {
	width: 100vw;
	text-align: left;
	position:relative;
	z-index: 1;
	overflow-y: scroll;
	scrollbar-width: none;
}

.pact-timeline-panel::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
}

.twitter-year-panel-bg {
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	filter: brightness(0.3) grayscale(100%);
}

.pact-year-header {
	font-size: 4em;
	margin-bottom: 0em;
}

.twitter-container {
	height: fit-content;
	/*background: #000000de;*/
	text-align: center;
}
.twitter-timeline {
	color: white;
	text-decoration: none;
	padding-top: 1em;
	display: block;
}
.twitter-timeline > .underline {
	text-decoration: underline;
}

.bg-hack {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
}
@media screen and (min-width: 769px) {
	.pact-timeline-panel > .content {
		width: 35em;
		margin: 0 auto;
	}

	.twitter-timeline-container.slideLeft {
	    animation: slideLeftBigTime 3000ms forwards;
	}

	.twitter-timeline-container.reverse {
	    animation: slideRightBigTime 3000ms forwards;
	}
}