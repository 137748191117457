.interactive-container {
	background: black;
	height: 200vh;
}

.chart-panel-container {
	height: 100vh;
	width: 100%;
	position: fixed;
	opacity: 0;
	pointer-events: none;
}

.chart-header-container {
	min-height: 18vh;
	min-height: calc(var(--vh, 1vh) * 18);
	padding: 0em .6em;
	padding-top: 4em;
}

.chart-body-container {
	position: relative;
	height: 82vh;
	height: calc(var(--vh, 1vh) * 72);
	width: 100vw;
}

.chart-header {
	font-size: 1.35em;
	margin: 0em;
}

.line {
	fill: none;
	stroke: steelblue;
	stroke-width: 2px;
}

.whole-svg-container {
	height: inherit;
}

.invisi-div {
	height: 480vh;
	width: 100%;
}

svg text {
	font-size: 1.18em;
}

.datascroll-year-container {
	position: absolute;
	top: 0;
	right: 5vh;
	border: 5px white solid;
	padding: .2em 1em;
	font-size: 1.5em;
	margin: 0em;
	font-weight: initial;
	opacity: 0;
}

.key-container {
	position: absolute;
	border: white 4px solid;
	padding-right: .6em;
	position: absolute;
	bottom: 0px;	
	max-height: 2em;
	height: auto;
	overflow: hidden;
	background: #000000d6;
	transition: max-height 400ms;
	cursor: pointer;
	right: 0;
	left: 0;
	margin: 0 auto;
	width: max-content;
	pointer-events: all;
}

.key-container.fade-4000-delay-animate-enter, .datascroll-year-container.fade-4000-delay-animate-enter {
  opacity: 0;
}
.key-container.fade-4000-delay-animate-enter-done, .datascroll-year-container.fade-4000-delay-animate-enter-done {
    opacity: 1;
}
.key-container.fade-4000-delay-animate-enter-active, .datascroll-year-container.fade-4000-delay-animate-enter-active {
  opacity: 1;
  transition: opacity 2400ms 2100ms;
}
.key-container.fade-4000-delay-animate-exit, .datascroll-year-container.fade-4000-delay-animate-exit {
  opacity: 1;
}
.key-container.fade-4000-delay-animate-exit-active, .datascroll-year-container.fade-4000-delay-animate-exit-active {
  opacity: 0;
  transition: opacity 700ms 100ms;
}

.key-container.fade-4000-delay-animate-exit-done, .datascroll-year-container.fade-4000-delay-animate-exit-done {
  opacity: 0;
}

.key-container.open {
	max-height: 15em;
	opacity: 1;
}

.key-container p {
	margin: 0;
}

.key-entry {
/*	display: flex;
*/}

.key-color {
	background-color: rgb(111, 26, 150);
	width: 1em;
	height: 1em;
	display: inline-block;
	margin: .5em .7em .5em .5em;
}

.legend.key-tag {
	margin: .3em auto;
	text-decoration: underline;
}

@media screen and (min-width: 769px) {
	.key-container {
		right: 10em;
		bottom: 15em;
		left: unset;
	}
}

@media screen and (min-width: 1000px) {
	.interactive-container {
		margin: auto;
		width: 100vw;
	}

	.chart-header {
		font-size: 2em;
		margin: 0em 4em;
	}

	.chart-content {
		width: 55vw;
		margin: 0 auto;
	}

	.key-container.cursor-pointer {
		cursor: unset;
	}

	.datascroll-year-container {
		right: 40vh;
	}

	.caption-full.no-scroll {
   		width: 22em;
	}
	.caption-icon.no-scroll {
		margin: 1em;
	}
}