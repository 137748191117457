.interactive-main {
    top: 0;
    left: 0;
    position: absolute;
    background: black;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding-right: 15px;  
    overflow: hidden;
    overscroll-behavior: none;
}

.mapSizingContainer {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    position: absolute;
}

.interactive-main.grounded {
    overflow: scroll;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
}

.interactive-main.landscape-fix {
    height: 100vh;
    overflow-y: unset;
    overscroll-behavior: none;
}  

.interactive-main.padding-bottom {
    padding-bottom: 15px;
}

.intersect-invisi-div {
    width: 100%;
}
.intersect-invisi-div.fit-to-screen {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

.audio-icon.player-mode {
    z-index: 10000
}

.fullscreen-lightbox-tapper {
    width: 100%;
    height: 100%;
    z-index: 21;
    background: black;
    margin: 0 auto;
    overflow: hidden;
    position: fixed;
    transition: opacity 500ms ease;
    top: 0;
    left: 0;
}

.blank-loading-screen {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 100000;
    background: black;
}

.animate-fade-in {
    animation: cheat-fade-in 300ms;
}

.expand-animation {
    opacity: 1;
}

.lb-close-x {
    position: absolute;
    left: 1.1em;
    top: 1.3em;
    z-index: 22;
    width: 2em;
    height: 2em;
}

.modalIcon {
    background-color: black;
    font-size: 1.5em;
    width: 50px;
    height: 50px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: 2px solid #acacac;
}

.modalPanel {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 1001;
    background: black;
    left: 0;
    right: 0;
    margin: auto;
    text-align: left;
    overflow-x: hidden;
    overflow-y: auto;
}

.modalContent {
    margin: 5em 1em;
}
.modalCreditsButton {
    bottom: 2em;
    right: 2em;
    float: right;
    text-decoration: underline;
    margin-top: 1em;
}

.modalCreditsButton.stickyFloat {
    position: sticky;
    float: right;
    margin-right: -4em;
}

.creditsContainer > p {
    margin: 0;
}

.tray {
    display: flex;
    position: absolute;
    bottom: 3vh;
    right: 1em;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    z-index: 1001;
}

.foldableShare {
    width: 50px;
    height: 50px;
    margin-top: .5em;
    background-color: black;
    border: 2px solid #acacac;
    transition: width .75s, border-radius .75s;
}

.foldableShare.open {
    width: 275px;
}

/*.modalContent > h1 {
    text-align: left;
}

.modalContent > p {
    text-align: left;
}*/

.rel-container {
    position: relative;
}

.segment-container {
    display: flex;
    height: .6em;
    margin: 1em 4em 0em;
}

.segment {
    border: 1.5px solid white;
    background: black;
    margin: 0em .1em;
    z-index: 22;
}

.tap-area {
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 21;
}

.next-tap {
    width: 60%;
    right: 0;
}

.prev-tap {
    width: 40%;
    left: 0;
}

.text-box-default {
    outline: 2px solid #000;
    outline-offset: -26px;
    margin: 2em auto;
    text-align: left;
    background: white;
    color: black;
    padding: 3em;
    max-width: 0em;
    max-height: 0em;
}

.text-box-default.dark {
    background: black;
    color: white;
    outline: 2px solid #fff;
}

.text-box-default-static {
    outline: 2px solid #fff;
    outline-offset: -26px;
    text-align: left;
    background: black;
    color: white;
    padding: 3em;
    height: auto;
    width: 68%;
    margin: 0 auto;
    z-index: 1;
}

.tb-spaced {
    margin: 0em auto 65em;
    opacity: 0;
}

.tb-spaced.last {
    margin-bottom: 10em;
}

.tb-spaced > p {
    opacity: 0;
}

.tb-spaced.slide-in > p {
    opacity: 1;
    transition: opacity 600ms 200ms ease;
}

.tb-spaced.slide-in {
    opacity: 1;
    max-width: 13em;
    max-height: 24em;
    transition: opacity 600ms ease, max-width 220ms ease, max-height 220ms ease;
    /*animation: slide-in 0.4s forwards;
    -webkit-animation: slide-in 0.4s forwards;*/
}

/* LONG SCROLL */
.long-scroll-container {
    background: black;
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -webkit-touch-callout : none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.long-scroll-pane {
    height: 100vh;
    width: 100%;
}

.long-scroll-pane.unmount {
    position: unset;
}

.long-scroll-pane.faded{
    opacity: 0;
}

.static-scrollyteller-container {
    height: 100vh;
    width: 100vw;
    pointer-events: none;
}
.static-scrollyteller-img {
    height: 100vh;   
    overflow: hidden;
    width: 100vw;  
    object-fit: cover; 
}

.block.static-scrollyteller-img {
    display: block;
}
.static-scrollyteller-img.vid {
    width: 100%;
}

/* SHARE */
.share-module {
    margin: 0 auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: max-content;
}

.share-module > p {
    margin-bottom: 0;
}

.innerShare {
    width: 120px;
}

.share-icon {
    width: 2em;
    height: 2em;
    margin: 1em;
    position: relative;
    background: white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    border: 2px white solid;
    cursor: pointer;
}

.share-icon.marginPoint5 {
    margin: .5em;
}

.copied-flag {
    opacity: 0;
    cursor: default;
}

.copied-flag-abso {
    position: absolute;
    left: -3.5em;
    transform: translateY(-50%);
    top: 50%;
    opacity: 0;
    cursor: default;
}

.lowerThird {
    position: absolute;
    z-index: 10000;
    top: 2em;
    left: 1em;
    font-size: 120%;
}

.lowerThird > h1 {
    margin-bottom: 0em;
}

.lowerThird > p {
    margin: 0;
    font-size: 110%;
}

/* CLASS OVERRIDES */
.chart-panel-container.fade-animate-enter-done {
    opacity: 1;
}
.chart-panel-container.fade-600-animate-enter-done {
    opacity: 1;
}

.key-container.fade-4000-delay-animate-enter, .datascroll-year-container.fade-4000-delay-animate-enter, .caption-icon.no-scroll.fade-4000-delay-animate-enter {
  opacity: 0;
}
.key-container.fade-4000-delay-animate-enter-done, .datascroll-year-container.fade-4000-delay-animate-enter-done, .caption-icon.no-scroll.fade-4000-delay-animate-enter-done {
    opacity: 1;
}
.key-container.fade-4000-delay-animate-enter-active, .datascroll-year-container.fade-4000-delay-animate-enter-active, .caption-icon.no-scroll.fade-4000-delay-animate-enter-active {
  opacity: 1;
  transition: opacity 2400ms 2100ms;
}
.key-container.fade-4000-delay-animate-exit, .datascroll-year-container.fade-4000-delay-animate-exit, .caption-icon.no-scroll.fade-4000-delay-animate-exit {
  opacity: 1;
}
.key-container.fade-4000-delay-animate-exit-active, .datascroll-year-container.fade-4000-delay-animate-exit-active, .caption-icon.no-scroll.fade-4000-delay-animate-exit-active {
  opacity: 0;
  transition: opacity 700ms 100ms;
}
.key-container.fade-4000-delay-animate-exit-done, .datascroll-year-container.fade-4000-delay-animate-exit-done, .caption-icon.no-scroll.fade-4000-delay-animate-exit-done {
  opacity: 0;
}


.static-scrollyteller-img.fade-600-animate-enter {
  opacity: 0;
}
.static-scrollyteller-img.fade-600-animate-enter-done {
    opacity: 1;
}
.static-scrollyteller-img.fade-600-animate-enter-active {
  opacity: 1;
  transition: opacity 600ms;
}
.static-scrollyteller-img.fade-600-animate-exit {
  opacity: 1;
}
.static-scrollyteller-img.fade-600-animate-exit-active {
  opacity: 0;
  transition: opacity 100ms 600ms;
}

/* Fade 600 Reverse */
.fade-600-reverse-animate-enter {
  opacity: 1;
}
.fade-600-reverse-animate-enter-active {
  opacity: 0;
  transition: opacity 600ms;
}
.fade-600-reverse-animate-enter-done {
    opacity: 0;
}
.fade-600-reverse-animate-exit {
  opacity: 0;
}
.fade-600-reverse-animate-exit-active {
  opacity: 1;
  transition: opacity 600ms;
}
.fade-600-reverse-animate-exit-done {
  opacity: 1;
}


/* FADE 300ms */
.fade-animate-enter {
  opacity: 0;
}
.fade-animate-enter-done {
    opacity: 1;
}
.fade-animate-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-animate-exit {
  opacity: 1;
}
.fade-animate-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.fade-delay-animate-enter {
  opacity: 0;
}
.fade-delay-animate-enter-done {
    opacity: 1;
}
.fade-delay-animate-enter-active {
  opacity: 1;
  transition: opacity 300ms 500ms;
}
.fade-delay-animate-exit {
  opacity: 1;
}
.fade-delay-animate-exit-active {
  opacity: 0;
  transition: opacity 300ms 500ms;
}

/* FADE 600ms */
.fade-600-animate-enter {
  opacity: 0;
}
.fade-600-animate-enter-done {
    opacity: 1;
}
.fade-600-animate-enter-active {
  opacity: 1;
  transition: opacity 600ms;
}
.fade-600-animate-exit {
  opacity: 1;
}
.fade-600-animate-exit-active {
  opacity: 0;
  transition: opacity 600ms;
}

/* FADE 600ms DELAY */
.fade-600-delay-animate-enter {
  opacity: 0;
}
.fade-600-delay-animate-enter-done {
    opacity: 1;
}
.fade-600-delay-animate-enter-active {
  opacity: 1;
  transition: opacity 1000ms 600ms;
}
.fade-600-delay-animate-exit {
  opacity: 1;
}
.fade-600-delay-animate-exit-active {
  opacity: 0;
  transition: opacity 1000ms 600ms;
}


/* FADE 4000ms DELAY */
.fade-4000-delay-animate-enter {
  opacity: 0;
}
.fade-4000-delay-animate-enter-done {
    opacity: 1;
}
.fade-4000-delay-animate-enter-active {
  opacity: 1;
  transition: opacity 2000ms 2000ms;
}
.fade-4000-delay-animate-exit {
  opacity: 1;
}
.fade-4000-delay-animate-exit-active {
  opacity: 0;
  transition: opacity 2000ms 2000ms;
}

.marker-circle {
    width: 2.5em;
    height: 2.5em;
    border-radius: 100%;
    position: relative;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 3px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000073;
}

.marker-inner {
    background-color: black;
    width: .75em;
    height: .75em;
    border-radius: 100%;
    margin: 0 auto;
}

/* GLOW RING */
.ring:before, .ring:after {
    z-index: 9998;
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0%;
    left: 0%;
    border-radius: 50%;
    box-shadow: 0 0 15px #fff;
    animation: glow-grow 3s ease-out infinite;
}
.ring:after {
    animation-delay: 3s;
}

.blob {
    background: #00000047;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    margin: 10px;
    height: 25px;
    width: 25px;
    transform: scale(1);
    border: 4px solid black;
}

.blob.blue {
    background: rgba(39, 52, 159, .7);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    animation: pulse-blue 2s infinite;
    border: 4px solid black;
}

.blob.red {
    background: rgba(140, 10, 10, .7);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    animation: pulse-red 2s infinite;
    border: 4px solid black;
}

.blob.purple {
    background: rgba(74,20,68,.7);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    animation: pulse-purple 2s infinite;
    border: 4px solid black;
}

@keyframes pulse-blue {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(39, 52, 159, 0.7);
    }
    
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(140, 10, 10, 0.7);
    }
    
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes pulse-purple {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(74,20,68, 0.7);
    }
    
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.black-ring:before, .black-ring:after {
    z-index: 900;
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0%;
    left: 0%;
    border-radius: 50%;
    box-shadow: 0 0 15px #000, inset 0 0 3px #000;
    animation: glow-grow-small 3s ease-out infinite;
}
.black-ring:after {
    animation-delay: 3s;
}
@keyframes glow-grow-small {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      transform: scale(1.55);
      opacity: 0;
    }
}

@keyframes glow-grow {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
}

/* BESPOKE PLAYER */

.bespoke-container {
    position: relative;
    height: 100%;
    width: 100%;
}

.bespoke-controls {
    position: absolute;
    top: 1em;
    left: .6em;
    right: 0;
    width: 3em;
    height: 11em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
    justify-items: space-evenly;
    -webkit-align-items: end;
    align-items: center;
    -webkit-flex-direction: column;
    flex-direction: column;
    pointer-events: all;
}

.bespoke-player-icon {
    width: 3em;
    height: 3em;
    pointer-events: all;
}

.bespoke-player-icon.pause {
    width: 1.6em;
    height: 2.5em;
    pointer-events: all;
}

.bespoke-play {
    width: 23em;
    height: 23em;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    box-shadow: 5px 5px 20px #000;
    border-radius: 100%;
    pointer-events: all;
    z-index: 10001;
}

.bespoke-pos-rel {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: inline-block;
    text-align: center;
    -webkit-animation:spin 20s linear infinite;
    -moz-animation:spin 20s linear infinite;
    animation:spin 20s linear infinite;
}

.bespoke-play.circle {
    font-family: 'VT323', monospace;
    font-weight: bolder;
    box-shadow: unset;
    border-radius: unset;
}

.bespokeFadeOut {
    animation: bespokeFaderOut .8s forwards;
}

.placeholder-gradient {
    width: 100%;
    height: 100%;
    background: rgba(124,124,133,1);
    background: -moz-linear-gradient(top, rgba(124,124,133,1) 0%, rgba(170,170,184,0) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(124,124,133,1)), color-stop(100%, rgba(170,170,184,0)));
    background: -webkit-linear-gradient(top, rgba(124,124,133,1) 0%, rgba(170,170,184,0) 100%);
    background: -o-linear-gradient(top, rgba(124,124,133,1) 0%, rgba(170,170,184,0) 100%);
    background: -ms-linear-gradient(top, rgba(124,124,133,1) 0%, rgba(170,170,184,0) 100%);
    background: linear-gradient(to bottom, rgba(124,124,133,1) 0%, rgba(170,170,184,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7c7c85', endColorstr='#aaaab8', GradientType=0 );
}

@keyframes bespokeFaderOut {
    0%   { opacity: 1; }
    99%  { opacity: .01;}
    100% { 
        opacity: 0;
        display: none;
    }
}

@-moz-keyframes spin { 100% { transform-origin: center center; -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spin { 100% { transform-origin: center center; -webkit-transform: rotate(-360deg); } }
@keyframes spin { 100% { transform-origin: center center; -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

@keyframes cheat-fade-in {
    0% { 
        width: 0%;
        height: 0%;
        opacity: 0; 
    }
    100% { 
        height: 100%;
        width: 100%;
        opacity: 1; 
    }
}

@-webkit-keyframes cheat-fade-in {
    0% { 
        width: 0%;
        height: 0%;
        opacity: 0; 
    }
    100% { 
        height: 100%;
        width: 100%;
        opacity: 1; 
    }
}

.animate__zoomIn {
  -webkit-animation: zoomIn 500ms forwards;
  animation: zoomIn 500ms forwards;
/*  transform: scale(0.3, 0.3);
*/}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.3, 0.3);
    transform: scale(0.3, 0.3);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
    
@keyframes slide-in {
/*    100% { transform: translateX(0%); }
*/}

@-webkit-keyframes slide-in {
/*    100% { -webkit-transform: translateX(0%); }
*/}

@media screen and (min-width: 769px) {
    .panel-slide-animate-enter {
    /*    margin-left: 0vw;
    */    transform: scale(1)
    }
    .panel-slide-animate-enter-active {  
        transform: scale(.4);
        margin-left: -110vw;
        margin-top: -170vh;
        transition: all 1350ms;
    }
    .panel-slide-animate-enter-done { 
        transform: scale(1);
        margin-left: -400vw;
        margin-top: 0vh;
        transition: margin-left 1350ms, margin-top 900ms 500ms, transform 900ms 500ms;
    }

    .panel-slide-animate-exit {
        margin-left: -400vw;
        transform: scale(1);
    }
    .panel-slide-animate-exit-active {
        transform: scale(.4);
        margin-left: -290vw;
        margin-top: -170vh;
        transition: all 1350ms;
    }
    .panel-slide-animate-exit-done {
        transform: scale(1);
        margin-left: 0vw;
        margin-top: 0vh;
        transition: margin-left 1350ms, margin-top 900ms 500ms, transform 900ms 500ms;
    }

    .modalPanel {
        top: 0;
        bottom: 0;
        width: 40em;
        height: 90vh;
        height: calc(var(--vh, 1vh) * 90);
        left: 0;
        right: 0;
        margin: auto;
        overflow: auto;
    }
    .modalContent {
        margin: 5em 5em 1em;
    }
}

@media only screen and (min-width: 768px) 
  and (max-width: 1024px) {
    .interactive-main.grounded {
        height: 355vh;
    }
}

@media only screen and (min-width: 1024px) {
    .interactive-main {
        position: absolute;
        height: 100vh;
        width: 400vw;
    }

    .interactive-main.fixed {
        position: fixed;
    }

    .interactive-main.grounded {
        position: absolute;
        height: 100vh;
        width: 400vw;
    }

    .text-box-default {
        outline-offset: -30px;
        padding: 5em;
    }

    .text-box-default-static {
        width: 50%;
    }

    .tb-spaced.slide-in {
        max-width: 30em;
    }

    .static-scrollyteller-img {
        width: 100%;  
        height: 100vh; 
        object-fit: cover;
    }

    .bespoke-controls {
        width: 3em;
        height: 12em;
    }
}